<template>
    <div>
        <div class="card">
            <!-- START CAMPAINGS -->
            <div>
                <div class="card-header">
                    <h3
                        class="mb-0 title--click title--screen"
                        @click="
                            $router.push({
                                path: '/campaign/list',
                                query: { is_approved: 0 }
                            })
                        "
                    >
                        承認待ちキャンペーン
                    </h3>
                </div>
                <div class="card-body" v-if="listDataCampain.length && !isLoading">
                    <TableBox
                        :list-data="listDataCampain"
                        :infor-page="paginationCP"
                        :inforEditRecord="{
                            isEdit: false
                        }"
                        @refresh="handleRefresh"
                    />
                </div>
                <div v-else-if="isLoading" class="card-body d-flex justify-content-center align-items-center">
                    <LoadingIcon />
                </div>
                <div v-else class="card-body">
                    <Transition name="slide-fade__table--list" appear>
                        <p class="mt-3 text-center">
                            {{ $t('notificationCommon.noData') }}
                        </p>
                    </Transition>
                </div>
            </div>
            <!-- END CAMPAINGS -->

            <!-- START ADMIN CAMPAINGS -->
            <div>
                <div class="card-header">
                    <h3
                        class="mb-0 title--click title--screen"
                        @click="
                            $router.push({
                                path: '/campaign/list',
                                query: { person_in_charge_role: currentUser?.role?.id, is_approved: 1 }
                            })
                        "
                    >
                        管理者チーム
                    </h3>
                </div>
                <div class="card-body" v-if="listDataAdminCampain.length && !isLoading">
                    <TableBox
                        :list-data="listDataAdminCampain"
                        :infor-page="paginationCPAdmin"
                        :inforEditRecord="{
                            isEdit: true
                        }"
                        @refresh="handleRefresh"
                    />
                </div>
                <div v-else-if="isLoadingAdmin" class="card-body d-flex justify-content-center align-items-center">
                    <LoadingIcon />
                </div>
                <div v-else class="card-body">
                    <Transition name="slide-fade__table--list" appear>
                        <p class="mt-3 text-center">
                            {{ $t('notificationCommon.noData') }}
                        </p>
                    </Transition>
                </div>
            </div>
            <!-- END ADMIN CAMPAINGS -->

            <!-- START TEAMS -->
            <div>
                <div v-for="(team, k) in listDataTeamsParent.data" :key="k">
                    <div class="card-header">
                        <h3
                            class="mb-0 title--click title--screen"
                            @click="
                                $router.push({
                                    path: '/campaign/list',
                                    query: { is_approved: 1, person_in_charge_team: team?.id }
                                })
                            "
                        >
                            {{ team.value }}
                        </h3>
                    </div>
                    <div class="card-body" v-if="listDataTeamChild[k] && !isLoadingTeam">
                        <TableBox
                            :list-data="listDataTeamChild[k]"
                            :infor-page="paginationTeam"
                            :inforEditRecord="{
                                isEdit: true
                            }"
                            @refresh="handleRefresh"
                        />
                    </div>
                    <div v-else-if="isLoadingTeam" class="card-body d-flex justify-content-center align-items-center">
                        <LoadingIcon />
                    </div>
                    <div v-else class="card-body">
                        <Transition name="slide-fade__table--list" appear>
                            <p class="mt-3 text-center">
                                {{ $t('notificationCommon.noData') }}
                            </p>
                        </Transition>
                    </div>
                </div>
            </div>
            <!-- END TEAMS -->
        </div>
    </div>
</template>

<script>
import { masterMethods, campaignMethods, authComputed, dashboardMethods } from '@/state/helpers';
import { keyMaster } from '@/config/constants';
import { campaignStatus } from '@/config/var-common';

import appConfig from '@/app.config';
import TableBox from '@/views/pages/dashboard/Tablebox/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
export default {
    page: {
        title: '管理者',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        TableBox,
        LoadingIcon
    },
    data() {
        return {
            //  START ADMIN CAMPAINGS
            paginationCPAdmin: {
                total: 0,
                page: 1,
                limit: 5
            },
            listDataAdminCampain: [],
            hasCP: {
                person_in_charge_role: ''
            },
            emptyAdminDataCP: false,
            isLoadingAdmin: false,
            // END ADMIN  CAMPAINGS

            //  START CAMPAINGS
            paginationCP: {
                total: 0,
                page: 1,
                limit: 5
            },
            listDataCampain: [],
            filterCP: {
                is_approved: ''
            },

            emptyDataCP: false,
            isLoading: false,
            // END  CAMPAINGS

            //  START TEAMS
            listDataTeamsParent: [],
            listDataTeamChild: [],
            paginationTeam: {
                total: 0,
                pageTeam: 1,
                limitTeam: 10
            },
            hasTeam: {
                person_in_charge_team: ''
            },
            isLoadingTeam: false
            //  END TEAMS
        };
    },
    watch: {},
    async mounted() {
        await this.getListCPAwaitApproval();
        await this.getListAdminCP();
        await this.getListTeams();
    },
    computed: {
        ...authComputed
    },
    methods: {
        ...masterMethods,
        ...campaignMethods,
        ...dashboardMethods,

        async handleRefresh() {
            await this.getListCPAwaitApproval();
            await this.getListAdminCP();
            await this.getListTeams();
        },

        goToDetail(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        getQueryString() {
            let query = [`page=${this.paginationCP.page}`, `limit=${this.paginationCP.limit}`, `sorts[id]=desc`];

            if (this.filterCP) {
                for (const [key, value] of Object.entries(this.filterCP)) {
                    if (key && value) query.push(`filters[${key}]=${encodeURIComponent(value)}`);
                }
            }

            if (this.hasCP) {
                for (const [key, value] of Object.entries(this.hasCP)) {
                    if (key && value) query.push(`has[${key}]=${encodeURIComponent(value)}`);
                }
            }

            if (this.hasTeam) {
                for (const [key, value] of Object.entries(this.hasTeam)) {
                    if (key && value) query.push(`has[${key}]=${encodeURIComponent(value)}`);
                }
            }

            return query.join('&');
        },

        getListCPAwaitApproval() {
            this.hasCP = null;
            this.isLoading = true;
            this.filterCP.is_approved = '0';
            this.hasTeam.person_in_charge_team = null;
            let query = this.getQueryString();
            this.GET_LIST_CAMPAIGN_FOR_DASHBOARD(query).then((data) => {
                this.isLoading = false;
                this.listDataCampain = data.data;
                this.paginationCP.total = data._metadata.total;
                this.paginationCP.page = data._metadata.page;
                this.paginationCP.limit = data._metadata.limit;
            });
        },

        getListAdminCP() {
            let { role } = this.currentUser;
            this.isLoadingAdmin = true;
            this.hasCP = {
                person_in_charge_role: role?.id ?? ''
            };
            this.filterCP.is_approved = '1';
            let query = this.getQueryString();
            this.GET_LIST_CAMPAIGN_FOR_DASHBOARD(query).then((data) => {
                this.onHanldeRenderStt(data);
                this.listDataAdminCampain = data.data;
                this.paginationCPAdmin.total = data._metadata.total;
                this.paginationCPAdmin.page = data._metadata.page;
                this.paginationCPAdmin.limit = data._metadata.limit;
                this.isLoadingAdmin = false;
            });
        },

        getListTeams() {
            this.hasCP = null;
            this.isLoadingTeam = true;
            this.filterCP.is_approved = 1;
            this.hasCP = {
                person_in_charge_role: '',
                person_in_charge_team: ''
            };
            this.listMaster(keyMaster['employee.department']).then((data) => {
                if (!data.length) return;
                const mapIdTeam = data.find((item) => item.id === 5);
                this.listDataTeamsParent = mapIdTeam;
                if (!mapIdTeam.data.length) return;
                let promises = [];
                for (let i = 0; i < mapIdTeam.data.length; i++) {
                    this.hasTeam.person_in_charge_team = mapIdTeam.data[i].id;
                    let query = this.getQueryString();
                    let promise = this.GET_LIST_CAMPAIGN_FOR_DASHBOARD(query).then((data) => {
                        this.paginationTeam.total = data._metadata.total;
                        this.paginationTeam.page = data._metadata.page;
                        this.paginationTeam.limit = data._metadata.limit;
                        this.onHanldeRenderStt(data);
                        return data.data;
                    });
                    promises.push(promise);
                }
                Promise.all(promises).then((results) => {
                    this.isLoadingTeam = false;
                    this.listDataTeamChild = results.filter((result) => result !== null);
                });
            });
        },

        onHanldeRenderStt(data) {
            data?.data?.forEach((element) => {
                if (element.progress_status) {
                    element.progress_status = campaignStatus.find((itemStt) => itemStt.id === element.progress_status);
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
