<template>
    <div class="table__box--container">
        <div v-if="inforEditRecord?.isEdit" class="text-right mb-3">
            <div v-if="!isVisibledEdit">
                <button class="btn btn-sm btn-primary" :disabled="dataTable.length <= 0" @click="handleToggleEditStatus">
                    {{ $t('btn.edit') }}
                </button>
            </div>
            <div v-else>
                <button class="btn btn-sm btn-light" @click="handleToggleEditStatus">{{ $t('btn.cancel') }}</button>
                <button class="btn btn-sm btn-success ml-3" @click="handleSaveStatus">{{ $t('btn.save') }}</button>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table mb-0 table-bordered table-box" style="min-height: 430px">
                <thead>
                    <tr>
                        <th>{{ $t('tableBox.stt') }}</th>
                        <th>{{ $t('tableBox.nameCP') }}</th>
                        <th>{{ $t('tableBox.progressStatus') }}</th>
                        <th>{{ $t('tableBox.secretariatStartDate') }}</th>
                        <th>{{ $t('tableBox.campaignEndDate') }}</th>
                        <th>{{ $t('tableBox.secretariatEndDate') }}</th>
                        <th>{{ $t('tableBox.personInCharge') }}</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody v-if="dataTable.length > 0 && !$props.isLoading">
                    <template v-for="(item, k) in dataTable">
                        <Transition name="slide-fade__table--list" appear :key="item.id">
                            <tr @dblclick="goToView('/campaign/form/view', item)" :class="`${item?.edited_status ? 'active--edited' : ''}`">
                                <td>
                                    {{ parseInt(($props.inforPage.page - 1) * $props.inforPage.limit) + parseInt(k + 1) }}
                                </td>
                                <td class="text-truncate" style="width: 280px; text-align: left">
                                    <p class="text-truncate" style="width: 280px; white-space: nowrap">
                                        {{ item.name }}
                                    </p>
                                </td>
                                <td :style="`background-color:${item?.edited_status ? '' : onHandleBgr(item.progress_status)}`" style="width: 150px">
                                    <div v-if="inforEditRecord && inforEditRecord?.isEdit" class="text-center" style="max-width: 130px">
                                        <Multiselect
                                            :id="`select-progres-status-dashboard-${item.id}`"
                                            :value.sync="item.progress_status"
                                            :options="campaignStatus"
                                            :config="{ trackBy: 'id', label: 'text', error: false, isRemove: true }"
                                            class="select_dasboard_all"
                                            :disabled="!isVisibledEdit"
                                            @select="onHandleChangeStatus(item)"
                                        />
                                    </div>
                                    <div v-else class="row-data-small">
                                        {{ renderNameProgress(item.progress_status?.id || item.progress_status) }}
                                    </div>
                                </td>
                                <td>
                                    <div class="row-data-small">{{ formatDateCommon(item.secretariat_start_date, 'dateTimeJP') ?? '' }}</div>
                                </td>
                                <td>
                                    <div class="row-data-small">{{ formatDateCommon(item.campaign_end_date, 'dateTimeJP') ?? '' }}</div>
                                </td>
                                <td>
                                    <div class="row-data-small">{{ formatDateCommon(item.secretariat_end_date, 'dateTimeJP') ?? '' }}</div>
                                </td>
                                <td class="truncate-cell" style="width: 250px; text-align: left">
                                    <p class="truncate-cell" style="width: 250px; white-space: nowrap">
                                        {{ item.person_in_charge?.name }}
                                    </p>
                                </td>
                                <td align="center">
                                    <div class="row-data-small">
                                        <a href="javascript:void(0)" class="text-dark" @click="goToDetail('campaign/form/view', item)">{{
                                            $t('btn.detail')
                                        }}</a>
                                    </div>
                                </td>
                            </tr>
                        </Transition>
                    </template>
                </tbody>
                <tbody v-else-if="$props.isLoading">
                    <template>
                        <tr style="position: relative; height: 80px">
                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                <LoadingIcon />
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <template>
                        <Transition name="slide-fade__table--list" appear>
                            <tr style="position: relative; height: 80px">
                                <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                    <p class="mt-3 text-center">
                                        {{ $t('notificationCommon.noData') }}
                                    </p>
                                </td>
                            </tr>
                        </Transition>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { campaignStatus } from '@/config/var-common';
import { formatDateCommon } from '@/utils/format';
import { campaignMethods } from '@/state/helpers';
import { showMessage } from '@/utils/messages';
import { cloneDeep } from 'lodash';

import LoadingIcon from '@/components/Loading/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';

export default {
    components: {
        LoadingIcon,
        Multiselect
    },
    props: {
        listData: {
            type: Array,
            required: true
        },
        inforPage: {
            type: Object
        },
        inforEditRecord: {
            type: [String, Number, Object]
        },
        isLoading: {
            type: Boolean,
            require: false,
            default: () => false
        }
    },
    data() {
        return {
            campaignStatus: campaignStatus,
            isVisibledEdit: false,
            listDataClone: [],
            dataTable: []
        };
    },
    methods: {
        ...campaignMethods,

        goToDetail(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },

        formatDateCommon(date, type) {
            return formatDateCommon(date, type);
        },

        goToView(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },

        handleToggleEditStatus() {
            this.isVisibledEdit = !this.isVisibledEdit;
            if (!this.isVisibledEdit) {
                this.dataTable?.forEach((ord) => {
                    const founDataClone = this.listDataClone.find((itd) => itd.id === ord.id);
                    if (founDataClone) {
                        ord.progress_status = founDataClone.progress_status;
                    }
                    if (ord?.edited_status) ord.edited_status = false;
                });
            }
        },

        renderNameProgress(params) {
            return campaignStatus.find((item) => item.id === params)?.text;
        },

        async handleSaveStatus() {
            let payload = {
                campaigns: this.listData.map((itemTable) => {
                    return {
                        id: itemTable?.id ?? null,
                        progress_status: itemTable?.progress_status?.id ?? null
                    };
                })
            };
            try {
                const requestStatusCP = await this.updateMassStatusCampaign(payload);
                if (requestStatusCP?.code !== 200) {
                    showMessage(requestStatusCP.code, this.$bvToast, requestStatusCP?.message);
                    return;
                }
                this.$emit('refresh');
            } catch (error) {
                console.log('error', error);
            }
        },

        onHandleChangeStatus(paramsRecord) {
            let { progress_status, id } = paramsRecord;
            paramsRecord.edited_status = this.listDataClone?.some((ocl) => ocl.id === id && progress_status?.id !== ocl?.progress_status?.id);
        },

        onHandleBgr(objb) {
            const foundStt = campaignStatus.find((item) => item.id === objb?.id);
            return foundStt?.backgroundColor;
        }
    },

    watch: {
        listData: {
            handler: function (newData) {
                this.dataTable = newData;
                this.listDataClone = cloneDeep(newData);
            },
            immediate: true
        }
    },
    mounted() {}
};
</script>

<style lang="scss">
.box--select.select_dasboard_all {
    .multiselect__content-wrapper {
        max-height: 200px !important;
    }
}
.active--edited {
    background-color: #1cbb8c70 !important;
}
</style>
