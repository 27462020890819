<template>
    <div>
        <div class="card">
            <!-- START CAMPAINGS -->
            <div>
                <div class="card-header">
                    <h3
                        class="mb-0 title--click title--screen"
                        @click="
                            $router.push({
                                path: '/campaign/list',
                                query: {
                                    part_time_employee: userInfo?.id,
                                    progress_status: sttEvent1.toString()
                                }
                            })
                        "
                    >
                        キャンペーン一覧 ( 運用中 )
                    </h3>
                </div>
                <div class="card-body">
                    <TableBox
                        :list-data="listDataCPProcessing"
                        :infor-page="processing"
                        :is-loading="isLoadingCPProcessing"
                        :inforEditRecord="{
                            isEdit: false
                        }"
                    />
                </div>
            </div>
            <!-- END CAMPAINGS -->

            <!-- START CAMPAINGS -->
            <div>
                <div class="card-header">
                    <h3
                        class="mb-0 title--click title--screen"
                        @click="
                            $router.push({
                                path: '/campaign/list',
                                query: {
                                    part_time_employee: userInfo.id,
                                    progress_status: sttEvent2.toString()
                                }
                            })
                        "
                    >
                        キャンペーン一覧 ( 開催前 )
                    </h3>
                </div>
                <div class="card-body">
                    <TableBox
                        :list-data="listDataCPBeforeTheEvent"
                        :infor-page="beforeTheEvent"
                        :is-loading="isLoadingCPBeforeTheEvent"
                        :inforEditRecord="{
                            isEdit: false
                        }"
                        @refresh="handleRefresh"
                    />
                </div>
            </div>
            <!-- END CAMPAINGS -->
        </div>
    </div>
</template>

<script>
import { masterMethods, campaignMethods, dashboardMethods } from '@/state/helpers';
import { state } from '@/state/modules/authfack';
import { campaignStatus } from '@/config/var-common';

import appConfig from '@/app.config';
import TableBox from '@/views/pages/dashboard/Tablebox/main.vue';

export default {
    page: {
        title: 'アルバイト',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        TableBox
    },
    data() {
        return {
            userInfo: state.user,
            sttEvent1: [4],
            sttEvent2: [1, 3],
            //  START CAMPAINGS_PROCESSING
            processing: {
                total: 0,
                page: 1,
                limit: 5
            },
            listDataCPProcessing: [],

            has: {
                part_time_employee: ''
            },
            filters: {
                progress_status: ''
            },
            isLoadingCPProcessing: false,
            // END  CAMPAINGS_PROCESSING

            //  START CAMPAINGS_BEFORE-THE-EVENT
            beforeTheEvent: {
                total: 0,
                page: 1,
                limit: 5
            },
            listDataCPBeforeTheEvent: [],
            isLoadingCPBeforeTheEvent: false
            // END  CAMPAINGS_BEFORE-THE-EVENT
        };
    },
    watch: {},
    mounted() {
        this.has.part_time_employee = this.userInfo.id;
        this.getListCPProcessing();
        this.getListCPBeforeTheEvent();
    },
    computed: {},
    methods: {
        ...masterMethods,
        ...campaignMethods,
        ...dashboardMethods,

        handleRefresh() {
            this.getListCPProcessing();
            this.getListCPBeforeTheEvent();
        },

        goToDetail(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        getQueryString(typeQ) {
            let query;
            if (typeQ === 'processing') {
                query = [`page=${this.processing.page}`, `limit=${this.processing.limit}`, `sorts[id]=desc`];
            } else if (typeQ === 'before_the_event') {
                query = [`page=${this.beforeTheEvent.page}`, `limit=${this.beforeTheEvent.limit}`, `sorts[id]=desc`];
            }

            if (this.filters) {
                for (const [key, value] of Object.entries(this.filters)) {
                    if (key && value) query.push(`filters[${key}]=${value.toString()}`);
                }
            }

            if (this.has) {
                for (const [key, value] of Object.entries(this.has)) {
                    if (key && value) query.push(`has[${key}]=${value.toString()}`);
                }
            }

            return query.join('&');
        },
        getListCPProcessing() {
            this.isLoadingCPProcessing = true;
            this.filters.progress_status = this.sttEvent1;
            this.has.part_time_employee = this.userInfo?.id;
            let query = this.getQueryString('processing');
            this.GET_LIST_CAMPAIGN_FOR_DASHBOARD(query).then((data) => {
                this.onHanldeRenderStt(data);
                this.listDataCPProcessing = data.data;
                this.processing.total = data._metadata.total;
                this.processing.page = data._metadata.page;
                this.processing.limit = data._metadata.limit;
                this.isLoadingCPProcessing = false;
            });
        },
        getListCPBeforeTheEvent() {
            this.isLoadingCPBeforeTheEvent = true;
            this.filters.progress_status = this.sttEvent2;
            this.has.part_time_employee = this.userInfo?.id;
            let query = this.getQueryString('before_the_event');
            this.GET_LIST_CAMPAIGN_FOR_DASHBOARD(query).then((data) => {
                this.onHanldeRenderStt(data);
                this.listDataCPBeforeTheEvent = data.data;
                this.beforeTheEvent.total = data._metadata.total;
                this.beforeTheEvent.page = data._metadata.page;
                this.beforeTheEvent.limit = data._metadata.limit;
                this.isLoadingCPBeforeTheEvent = false;
            });
        },

        onHanldeRenderStt(data) {
            data?.data?.forEach((element) => {
                if (element.progress_status) {
                    element.progress_status = campaignStatus.find((itemStt) => itemStt.id === element.progress_status);
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
