<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Swal from 'sweetalert2';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';

import { renderLineBreaks } from '@/utils/format';
import { keyMaster, masterComputed, masterMethods, faqMethods, checkPermission } from '@/state/helpers';
import { showMessage } from '@/utils/messages';

export default {
    page: {
        title: 'FAQ詳細',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        Footer
    },
    data() {
        return {
            index: 1,
            loading: false,
            id: this.$route.query.id,
            form: {
                sheet: {
                    id: ''
                },
                campaign_type: {
                    id: ''
                },
                has_answer_default: false,
                categories: [],
                application_type: [],
                question: '',
                answers_default: []
            },

            listSheetFAQ: [],
            listDataCPTypes: [],
            listApplicationType: [],
            listDataMasterCondition1: [],
            listDataMasterCondition2: [],
            submitted: false,
            submit: false
        };
    },
    computed: {
        ...masterComputed
    },

    methods: {
        ...masterMethods,
        ...faqMethods,
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        // call api
        async getListMasterTypes() {
            this.loading = true;
            try {
                const data = await this.listMaster(keyMaster['campaign.type']);
                this.listDataCPTypes = data;
            } catch (error) {
                console.log('error', error);
            } finally {
                this.loading = false;
            }
        },

        getListMasterSheets() {
            this.loading = true;
            this.listMaster(keyMaster['faq_master.sheets']).then((data) => {
                this.listSheetFAQ = data;
                this.loading = false;
            });
        },

        getDetail(id) {
            this.loading = true;
            this.detailFaq(id).then((data) => {
                const findApplicationType = this.listDataCPTypes.find((item) => item.id === data.campaign_type.id);
                if (findApplicationType) {
                    this.listApplicationType = findApplicationType.data;
                }

                this.form.sheet.id = data.sheet.id;
                this.form.sheet.value = data.sheet.value;
                this.form.question = data.question;
                this.form.category = data.categories;
                this.form.answers = data.answers;
                this.form.campaign_type.id = data.campaign_type.id;
                this.form.campaign_type.value = data.campaign_type.value;
                this.form.has_answer_default = data.has_answer_default;
                this.form.answers_default = data.answers;
                this.form.application_type = data.categories.map((item) => item.category.id);
                this.form.categories = data.categories;
                this.loading = false;
            });
        },

        goToEdit(path, id, client_id) {
            this.$router.push({
                path: path,
                query: { id: id, client_id: client_id }
            });
        },

        renderTitleBox(idCategory) {
            return this.listApplicationType.find((item) => item.id === idCategory)?.value;
        },
        textLineBreaks(text) {
            return renderLineBreaks(text);
        },
        deleteObject(data) {
            Swal.fire({
                title: 'このFAQ を削除してもよろしいですか。',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#5664d2',
                cancelButtonColor: '#b5b8bf',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deleteFaq(data).then((data) => {
                        if (data.code == 200) {
                            this.$router.push({ path: '/faqs' }).then(() => {
                                showMessage(data.code, this.$bvToast, 'FAQが削除されました。');
                            });
                        } else {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                    });
                }
            });
        }
    },
    async mounted() {
        await this.getListMasterTypes();
        this.getListMasterSheets();
        if (this.$route.query.id) {
            this.getDetail(this.$route.query.id);
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__body--card form__common--center" v-if="!loading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation">
                                    <div class="form-group">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('faq.sheets') }} </label>
                                        <div class="col-sm-9">
                                            {{ form.sheet ? form.sheet.value : '' }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('faq.campaignType') }} </label>
                                        <div class="col-sm-9">
                                            <div class="d-flex">
                                                {{ form.campaign_type ? form.campaign_type.value : '' }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('faq.applicationType') }} </label>
                                        <div class="col-sm-9">
                                            <div v-if="form.category">
                                                <span v-for="(i, k) in form.category" :key="'pic' + k"
                                                    >{{ k > 0 ? ', ' : '' }}{{ i.category ? i.category.value : '' }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('faq.question') }} </label>
                                        <div class="col-sm-9">
                                            <div v-html="textLineBreaks(form.question)"></div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('faq.commonAsk') }} </label>
                                        <div class="col-sm-9">
                                            <div v-if="form.has_answer_default">
                                                <div v-for="(pASk, k) in form.answers" :key="'p' + k" class="mb-3">
                                                    <div class="d-flex align-items-center" style="column-gap: 15px">
                                                        <div class="w-100">
                                                            <div v-html="textLineBreaks(form.answers[k].answer)"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Loop Form  -->
                                    <div v-if="form.application_type.length">
                                        <div
                                            v-for="(ct, dx) in form.categories"
                                            :key="dx"
                                            class="py-3 mt-3"
                                            style="box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px; border-radius: 10px"
                                        >
                                            <label class="col-sm-3 mw--form mb-3" for="validationCustom05"
                                                >{{ renderTitleBox(ct.category.id) }}
                                            </label>
                                            <div v-for="(ch, k) in ct.category_conditions" :key="`ch${k}`">
                                                <hr />
                                                <div class="form-group">
                                                    <label class="col-sm-3" for="validationCustom05">{{ $t('faq.condition1') }} </label>
                                                    <div class="col-sm-9">
                                                        {{ ch.application_condition_1 ? ch.application_condition_1.value : '' }}
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label class="col-sm-3" for="validationCustom05">{{ $t('faq.condition2') }} </label>
                                                    <div class="col-sm-9">
                                                        <div class="d-flex flex-wrap">
                                                            <div v-if="ch.application_condition_2_ids">
                                                                <span v-for="(i, k) in ch.application_condition_2_ids" :key="'picss' + k"
                                                                    >{{ k > 0 ? ', ' : '' }}{{ i.value }}</span
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="!form.has_answer_default" class="form-group">
                                                    <label class="col-sm-3" for="validationCustom05">{{ $t('faq.ask') }} </label>
                                                    <div class="col-sm-9">
                                                        <div v-if="ch.answers_ids">
                                                            <div v-for="(i, k) in ch.answers_ids" :key="'picsss' + k">{{ i.answer }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill box__button--custom">
                        <button
                            type="button"
                            class="btn btn-light mr-3"
                            @click="
                                $router.push({
                                    path: '/faqs'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button v-if="checkPermiss('faq.edit')" type="button" class="btn btn-primary" @click="goToEdit('/faq/form', $route.query.id)">
                            {{ $t('btn.edit') }}
                        </button>
                    </div>
                    <div v-if="$route.query.id">
                        <button
                            v-if="checkPermiss('faq.delete')"
                            type="btn"
                            class="btn btn-danger ml-3"
                            @click="deleteObject({ id: $route.query.id })"
                        >
                            {{ $t('btn.delete') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
